import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const navProps = [
  {
    navLink: '#',
    navTitle: 'Services',
    type: EXTERNAL_LINK,
    navSubmenu: [
      {
        navLink: '/services/digital-product-engineering/',
        navTitle: 'Digital Product Engineering',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/services/project-strategy/',
            type: EXTERNAL_LINK,
            navTitle: 'Product Strategy',
            menuIcon: images.productStrategyMicon,
          },
          {
            navLink: '/services/enterprise-architecture-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Architecture Advisory',
            menuIcon: images.architectureAdvisoryMicon,
          },
          {
            navLink: '/services/product-and-platform-engineering/',
            navTitle: 'Product and Platform Engineering',
            type: EXTERNAL_LINK,
            menuIcon: images.productEngDevMicon,
          },
          {
            navLink: '/services/product-and-platform-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Modernization',
            menuIcon: images.modernizationMicon,
          },
          {
            navLink: '/services/quality-engineering/',
            navTitle: 'QA Engineering',
            type: EXTERNAL_LINK,
            menuIcon: images.qaEngineeringMicon,
          },
          {
            navLink: '/services/product-sustenance/',
            navTitle: 'Managed Sustenance',
            type: EXTERNAL_LINK,
            menuIcon: images.sustenanceMicon,
          },
        ],
      },
      {
        navLink: '/services/cloud-native-engineering/',
        navTitle: 'Cloud and DevOps Engineering',
        navSubmenu: [
          {
            navLink: '/services/cloud-architecture-design/',
            type: EXTERNAL_LINK,
            navTitle: 'Architecture Reviews',
            menuIcon: images.architectureReviewsMicon,
          },
          {
            navLink: '/services/reliability-engineering/',
            type: EXTERNAL_LINK,
            navTitle: 'Reliability Engineering',
            menuIcon: images.reliabilityEngMicon,
          },
          {
            navLink: '/services/cloud-governance/',
            type: EXTERNAL_LINK,
            navTitle: 'Cloud Governance and Finops',
            menuIcon: images.cloudGovernanceMicon,
          },
          {
            navLink: '/services/cloud-migration-and-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Migration and Modernization',
            menuIcon: images.migrationModernizationMicon,
          },
          {
            navLink: '/services/devops-automation/',
            navTitle: 'DevOps Automation',
            type: EXTERNAL_LINK,
            menuIcon: images.devOpsAutomationMicon,
          },
          {
            navLink: '/services/cloud-infrastructure-management/',
            navTitle: 'Managed Services',
            menuIcon: images.managedServicesMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/services/microsoft-azure-services/',
            navTitle: 'Microsoft Azure',
            type: EXTERNAL_LINK,
            menuIcon: images.microsoftAzureMicon,
          },
        ],
      },
      {
        navLink: '/services/data-engineering/',
        type: EXTERNAL_LINK,
        navTitle: 'Data Engineering',
        navSubmenu: [
          {
            navLink: '/services/data-integration/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Integration',
            menuIcon: images.dataIntegrationMicon,
          },
          {
            navLink: '/services/data-platform-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Platform modernization',
            menuIcon: images.platformModernizationMIcon,
          },
          {
            navLink: '/services/data-analytics-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Big Data Analytics',
            menuIcon: images.bigDataAnalyticsMicon,
          },
          {
            navLink: '/services/data-visualization-services/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Visualization',
            menuIcon: images.dataVisualizationMicon,
          },
        ],
      },
      {
        navLink: '/services/gen-ai-ml-development/',
        type: EXTERNAL_LINK,
        navTitle: 'AI/ML Engineering',
        navSubmenu: [
          {
            navLink: '/services/generative-ai-development/',
            type: EXTERNAL_LINK,
            navTitle: 'GenAI',
            menuIcon: images.genAIMicon,
          },
          {
            navLink: '/services/data-science-consulting/',
            type: EXTERNAL_LINK,
            navTitle: 'Data Science',
            menuIcon: images.dataScienceMicon,
          },
          {
            navLink: '/services/machine-learning-development/',
            type: EXTERNAL_LINK,
            navTitle: 'Machine Learning',
            menuIcon: images.machineLearningMicon,
          },
          {
            navLink: '/services/mlops/',
            type: EXTERNAL_LINK,
            navTitle: 'MLOps',
            menuIcon: images.mlOpsMicon,
          },
        ],
      },
      {
        navLink: '/services/digital-experience-design/',
        type: EXTERNAL_LINK,
        navTitle: 'Digital and Experience Engineering',
        navSubmenu: [
          {
            navLink: '/services/ux-design/',
            navTitle: 'UX Design',
            menuIcon: images.uxDesignMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/services/application-development/',
            navTitle: 'Application Development',
            menuIcon: images.applicationDevMicon,
          },
          {
            navLink: '/services/application-modernization/',
            type: EXTERNAL_LINK,
            navTitle: 'Application Modernization',
            menuIcon: images.appModernizationMicon,
          },
          {
            navLink: '/services/iot-development/',
            navTitle: 'IoT and Connected Devices',
            menuIcon: images.iotDevicesMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/services/web3-development/',
            navTitle: 'Web 3.0 and XR',
            menuIcon: images.web3XrMicon,
            type: EXTERNAL_LINK,
          },
        ],
      },
    ],
  },
  {
    navLink: '#',
    navTitle: 'Industries',
    navSubmenu: [
      {
        navLink: '#',
        navTitle: 'Industries',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/industry/finance/',
            navTitle: 'BFSI',
            menuIcon: images.bfsiMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/healthcare/',
            navTitle: 'Healthcare',
            menuIcon: images.healthcareMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/retail/',
            navTitle: 'Retail',
            menuIcon: images.retailMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/supply-chain/',
            navTitle: 'Supply Chain',
            menuIcon: images.supplychainMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/industry/digital-natives/',
            navTitle: 'Hightech and digital natives',
            menuIcon: images.digitalNativesMicon,
            type: EXTERNAL_LINK,
          },
        ],
      },
    ],
  },
  {
    navLink: '/case-studies/',
    navTitle: 'Case Studies',
    type: EXTERNAL_LINK,
  },
  {
    navLink: '#',
    navTitle: 'Resources',
    navSubmenu: [
      {
        navLink: '#',
        navTitle: 'Resources',
        activeClass: 'active',
        navSubmenu: [
          {
            navLink: '/category/ai-ml-development/',
            navTitle: 'AI & ML Insights',
            menuIcon: images.aimlInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/category/product-engineering/',
            navTitle: 'Product Engineering Insights',
            menuIcon: images.productEngInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/category/cloud/',
            navTitle: 'Cloud Engineering Insights',
            menuIcon: images.cloudEngInsightsMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/blog/',
            navTitle: 'Blog',
            menuIcon: images.blogNMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/ebooks/',
            navTitle: 'Ebooks',
            menuIcon: images.ebooksNMicon,
            type: EXTERNAL_LINK,
          },
          {
            navLink: '/blog/#news&insights',
            navTitle: 'News and Tech Insights',
            menuIcon: images.newsNMicon,
            type: EXTERNAL_LINK,
          },
        ],
      },
    ],
  },
  {
    navLink: '/about-us/',
    navTitle: 'About Us',
  },
]
